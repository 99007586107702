<script lang="ts" setup>
import type { SetOptional } from 'type-fest'

import SkrButton from '@/components/button/SkrButton.vue'
import DialogLeavePage from '@/components/dialogs/DialogLeavePage.vue'
import SkrSwitch from '@/components/inputs/SkrSwitch.vue'
import TextInput from '@/components/inputs/TextInput.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import SaveChangesElement from '@/components/SaveChangesElement.vue'
import SignatureCropper from '@/components/SignatureCropper.vue'
import type { MenuItem } from '@/components/SkrContextMenu.vue'
import SignatureImage from '@/components/visual-signature/SignatureImage.vue'
import SkrContextMenu from '@/components/SkrContextMenu.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'
import type { CustomImageData } from '@/repository/visualSignature'

type SettingId = 'date' | 'claim' | 'standards'

const props = defineProps<{
  signature: SetOptional<VisualSignatureDetails, 'id'>
  operationType: 'create' | 'edit'
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const userStore = useUserStore()
const visualSignatureStore = useVisualSignatureStore()
const { signatures } = storeToRefs(visualSignatureStore)

const { t } = useI18n()

const signatureQuality = computed(() => {
  return userStore.attributes.default_quality?.[0] ?? userStore.highestSignatureQuality ?? 'ses'
})

const legislation = computed(() => {
  if (userStore.attributes.default_legislation?.[0]) return userStore.attributes.default_legislation[0]

  const qualityData = userStore.signatureQualities[signatureQuality.value]
  return qualityData?.eidas ? 'eidas' : 'zertes'
})

const provider = computed<string>(() => {
  const qualityData = userStore.signatureQualities[signatureQuality.value]

  return qualityData?.any?.provider ?? qualityData?.eidas?.provider ?? qualityData?.zertes?.provider ?? ''
})

const signatureInfo = reactive({
  alias: props.signature.alias,
  name: props.signature.name ?? '',
  company: props.signature.company ?? '',
  location: props.signature.location ?? '',
})

const initialAlias = props.signature.alias

const usedAliases = computed(() => {
  return signatures.value.map(signature => signature.alias).filter(alias => alias !== initialAlias)
})

const aliasRules = computed(() => [
  (value: string) => Boolean(value) || (t('profile.visual_signature.form.alias.error.required') as string),
  (value: string) =>
    !usedAliases.value.includes(value) || (t('profile.visual_signature.form.alias.error.unique') as string),
])

const userVisualSignPermissions = userStore.permissions.visualSignatures ?? {}

// if canUpdate is true, use user value if it exists (this is for edit signature), else use default value (this is for create new signature)
// if canUpdate is false, use default value
const switchValues = reactive<Record<SettingId, boolean>>({
  date: userVisualSignPermissions.date.canUpdate
    ? Boolean(props.signature.displayDate ?? userVisualSignPermissions.date.defaultValue)
    : userVisualSignPermissions.date.defaultValue,
  claim: userVisualSignPermissions.claim.canUpdate
    ? Boolean(props.signature.displayClaim ?? userVisualSignPermissions.claim.defaultValue)
    : userVisualSignPermissions.claim.defaultValue,
  standards: userVisualSignPermissions.std.canUpdate
    ? Boolean(props.signature.displayStandards ?? userVisualSignPermissions.std.defaultValue)
    : userVisualSignPermissions.std.defaultValue,
})

const visualSignatureSettings = computed<Record<SettingId, { label: string; isVisible: boolean }>>(() => {
  return {
    date: {
      label: t('profile.visual_signature.form.date_label') as string,
      isVisible: userVisualSignPermissions.date.canUpdate,
    },
    claim: {
      label: t('profile.visual_signature.form.claim_label') as string,
      isVisible: userVisualSignPermissions.claim.canUpdate,
    },
    standards: {
      label: t('profile.visual_signature.form.standards') as string,
      isVisible: userVisualSignPermissions.std.canUpdate,
    },
  }
})

const isVisualSignatureEditable = computed(() => {
  return (
    userVisualSignPermissions.date.canUpdate ||
    userVisualSignPermissions.std.canUpdate ||
    userVisualSignPermissions.claim.canUpdate
  )
})

const { language } = storeToRefs(useLanguageStore())

const visualSignatureParams = computed(() => {
  const params: Partial<VisualSignatureData & VisualSignatureDetails> = {
    id: props.signature.id,
    language: language.value,
    quality: signatureQuality.value,
    legislation: legislation.value,
    provider: provider.value,
    name: signatureInfo.name,
    company: signatureInfo.company,
    location: signatureInfo.location,
    displayClaim: switchValues.claim,
    displayDate: switchValues.date,
    displayStandards: switchValues.standards,
  }

  if (props.operationType === 'create') {
    params.image = customImageUpdate.value
  }

  return params
})

const personalSignatureImage = ref<InstanceType<typeof SignatureImage>>()
const rerenderVisualSignature = () => {
  personalSignatureImage.value?.updateImage()
}

const copy = computed(() => {
  if (props.operationType === 'create') {
    return {
      headerTitle: t('profile.visual_signature.create.header.title'),
      title: t('profile.visual_signature.create.title'),
      saveAction: t('profile.visual_signature.create_signature'),
    }
  } else {
    return {
      headerTitle: props.signature.alias,
      title: t('profile.visual_signature.edit.title'),
      saveAction: t('business.settings.save'),
    }
  }
})

const editedSignature = computed<Omit<VisualSignatureDetails, 'id'>>(() => ({
  alias: signatureInfo.alias,
  name: signatureInfo.name,
  company: signatureInfo.company,
  location: signatureInfo.location,
  displayClaim: switchValues.claim,
  displayDate: switchValues.date,
  displayStandards: switchValues.standards,
  hasCustomImage: isUsingCustomImage.value,
}))

const isValid = ref(true)

const hasUnsavedChanges = computed(() => {
  return !(
    props.signature.alias === editedSignature.value.alias &&
    props.signature.name === editedSignature.value.name &&
    props.signature.company === editedSignature.value.company &&
    props.signature.location === editedSignature.value.location &&
    isDisplayClaimUnchanged.value &&
    isDisplayDateUnchanged.value &&
    isDisplayStandardsUnchanged.value &&
    props.signature.hasCustomImage === editedSignature.value.hasCustomImage
  )
})

// if permission canUpdate is false - there is no permission to change on ui, so we don't show it as unsaved changes
// if permission canUpdate is true - we need to check if the value is changed
// for create page, permission value on signature is null, so we compare edited value with the default value
// for edit page, we can compare edited value with the value in the signature
const isDisplayClaimUnchanged = computed(() => {
  if (!userVisualSignPermissions.claim.canUpdate) {
    return true
  }
  return (
    editedSignature.value.displayClaim ===
    (props.signature.displayClaim ?? userVisualSignPermissions.claim.defaultValue)
  )
})

const isDisplayDateUnchanged = computed(() => {
  if (!userVisualSignPermissions.date.canUpdate) {
    return true
  }
  return (
    editedSignature.value.displayDate === (props.signature.displayDate ?? userVisualSignPermissions.date.defaultValue)
  )
})

const isDisplayStandardsUnchanged = computed(() => {
  if (!userVisualSignPermissions.std.canUpdate) {
    return true
  }
  return (
    editedSignature.value.displayStandards ===
    (props.signature.displayStandards ?? userVisualSignPermissions.std.defaultValue)
  )
})

const { navigationTrap, springTrap } = useNavigationTrap(hasUnsavedChanges)

const onClose = () => {
  // TODO: Abstract this pattern to make code more DRY
  if (hasUnsavedChanges.value) {
    void springTrap(() => {
      emit('close')
    })
  } else {
    emit('close')
  }
}

/**
 * Temporary image data for a custom signature image.
 *
 * In case of creating a new signature, we have no ID yet, so we can't assign the image
 * to the signature right away. Instead, we need to attach the image information to
 * the payload when creating the signature.
 */
const customImageUpdate = ref<CustomImageData | null>(null)

provide('imageData', customImageUpdate)

const saveCustomImage = async (imageData: CustomImageData) => {
  if (props.operationType === 'edit') {
    await visualSignatureStore.saveSignatureImage(props.signature.id!, imageData)
    await visualSignatureStore.updateSignatures()
    customImageUpdate.value = imageData
    rerenderVisualSignature()
  } else if (props.operationType === 'create') {
    customImageUpdate.value = imageData
  }
}

const removeCustomImage = async () => {
  if (props.operationType === 'edit') {
    await visualSignatureStore.removeSignatureImage(props.signature.id!)
    await visualSignatureStore.updateSignatures()
    customImageUpdate.value = null
    rerenderVisualSignature()
  } else if (props.operationType === 'create') {
    customImageUpdate.value = null
  }
}

const isUsingCustomImage = computed(() => {
  return Boolean(props.signature.hasCustomImage || customImageUpdate.value)
})

const setAsDefault = () => {
  if (hasUnsavedChanges.value) {
    void springTrap(() => {
      emit('action', 'select')
    })
  } else {
    emit('action', 'select')
  }
}

const deleteSignature = () => {
  if (hasUnsavedChanges.value) {
    void springTrap(() => {
      emit('action', 'delete')
    })
  } else {
    emit('action', 'delete')
  }
}

const contextMenuItems = computed<MenuItem[]>(() => [
  {
    label: t('visual_signature.item.set_default') as string,
    icon: 'custom:admin_guide',
    isHidden: props.signature.isDefault,
    action: setAsDefault,
  },
  {
    label: t('visual_signature.item.delete_signature') as string,
    icon: 'custom:minus_circle',
    color: 'danger',
    isHidden: signatures.value.length === 1,
    action: deleteSignature,
  },
])

const submit = () => {
  if (props.isLoading) return
  // TODO: Refactor this
  if (props.operationType === 'edit') {
    emit('action', 'complete', { updates: editedSignature.value })
  } else if (props.operationType === 'create') {
    emit('action', 'complete', { updates: editedSignature.value, customImage: customImageUpdate.value })
  }
}

const form = ref<{ validate: () => void } | null>(null)

if (props.operationType === 'create') {
  const unwatchForm = watchEffect(() => {
    if (form.value) {
      form.value.validate()
      unwatchForm()
    }
  })
}
</script>

<template>
  <overlay-base class="overlay-edit-signature">
    <template #header>
      <toolbar-base class="toolbar" :title="copy.headerTitle" :app="false" is-closeable @close="onClose">
        <template v-if="operationType === 'edit'" #options>
          <skr-context-menu :items="contextMenuItems" dense />
        </template>
      </toolbar-base>
    </template>
    <v-container class="container" fluid>
      <div class="content">
        <h1>{{ copy.title }}</h1>
        <v-container fluid>
          <v-row align="start">
            <v-col cols="12" lg="6" order="2" order-lg="1">
              <v-form ref="form" v-model="isValid">
                <section class="mb-4">
                  <div class="mb-4 | personal-signature__title shrink">
                    {{ $t('profile.visual_signature.form.alias.title') }}
                  </div>
                  <text-input
                    v-model="signatureInfo.alias"
                    data-cy="alias_input"
                    :hint="t('profile.visual_signature.form.alias.hint')"
                    :rules="aliasRules"
                    persistent-hint
                  />
                </section>
                <section>
                  <div class="mb-4 | personal-signature__title shrink">
                    {{ $t('profile.visual_signature.form.title') }}
                  </div>
                  <text-input
                    v-model="signatureInfo.name"
                    data-cy="name_input"
                    :label="t('profile.visual_signature.form.name_label')"
                    :hint="isUsingCustomImage ? t('profile.visual_signature.form.name_help') : ''"
                    :disabled="isUsingCustomImage"
                    persistent-hint
                  />
                  <text-input
                    v-model="signatureInfo.company"
                    class="mb-3"
                    data-cy="company_input"
                    :label="t('profile.visual_signature.form.optional_line.label')"
                    :hint="t('profile.visual_signature.form.optional_line.hint')"
                    persistent-hint
                  />
                  <text-input
                    v-model="signatureInfo.location"
                    class="mb-3"
                    data-cy="location_input"
                    :label="t('profile.visual_signature.form.optional_line_2.label')"
                    :hint="t('profile.visual_signature.form.optional_line_2.hint')"
                    persistent-hint
                  />
                </section>
              </v-form>
              <div class="mt-6 | personal-signature__title shrink">
                {{ t('profile.visual_signature.upload.title') }}
              </div>
              <div class="personal-signature__upload">
                <p>
                  {{ t('profile.visual_signature.upload.text') }}
                </p>
                <signature-cropper :signature-id="signature.id" @save="saveCustomImage" @remove="removeCustomImage" />
              </div>
            </v-col>
            <v-col cols="12" lg="6" order="1" order-lg="2">
              <div class="personal-signature__title shrink">
                {{ t('profile.visual_signature.preview.title') }}
              </div>
              <div class="personal-signature__stage grow">
                <div class="personal-signature__signature-wrapper elevation-3">
                  <signature-image
                    ref="personalSignatureImage"
                    :width="300"
                    :height="140"
                    :parameters="visualSignatureParams"
                    :owned="props.operationType === 'edit'"
                  />
                </div>
              </div>
              <template v-if="isVisualSignatureEditable">
                <div class="mt-6 | personal-signature__title shrink">
                  {{ t('profile.visual_signature.form.settings') }}
                </div>
                <div>
                  <template v-for="(setting, id) in visualSignatureSettings">
                    <v-row
                      v-if="setting.isVisible"
                      :key="id"
                      align="center"
                      justify="space-between"
                      class="personal-signature__settings"
                      no-gutters
                    >
                      <v-col>
                        <div class="d-flex align-center gap-2">
                          <div class="personal-signature__switch__label" :class="{ active: switchValues[id] }">
                            {{ setting.label }}
                          </div>
                        </div>
                      </v-col>
                      <v-col class="d-flex justify-end">
                        <skr-switch
                          v-model="switchValues[id]"
                          color="success"
                          :data-cy="`${id}_switch`"
                          :label="switchValues[id] ? $t('global.form.switch.true') : $t('global.form.switch.false')"
                          class="personal-signature__switch"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
    <save-changes-element
      class="save-element"
      :has-unsaved-changes="hasUnsavedChanges"
      :is-loading="isLoading"
      :is-disabled="!isValid"
      :action-text="copy.saveAction"
      @save="submit"
    >
      <template v-if="operationType === 'create'">
        <div class="action">
          <skr-button data-cy="save_button" size="xl" :loading="isLoading" :disabled="!isValid" @click="submit">
            {{ t('profile.visual_signature.create_signature') }}
          </skr-button>
        </div>
      </template>
    </save-changes-element>

    <dialog-leave-page
      v-model="navigationTrap.isResolving"
      @leave="navigationTrap.resolveNavigation(false)"
      @stay="navigationTrap.resolveNavigation(true)"
    />
  </overlay-base>
</template>

<style lang="sass" scoped>
.toolbar
  position: sticky
  top: 0
  :deep(.toolbar__options)
    height: 100%
    display: flex
    align-items: center
  :deep(.menu-activator)
    height: 100%
    width: auto
    color: $c-text
    aspect-ratio: 1 / 1
    border-radius: 0
    &::before
      background: $c-primary

.container
  height: calc(100% - 90px)
  overflow-y: auto

.content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: $site-width-narrow
  padding: 8 * $bw
  margin: 5rem auto 0
  > h1
    line-height: 1.2
    text-align: center
    margin-bottom: 10 * $bw
    +h1

.personal-signature

  &__title
    font: bold 1.125rem/1.1 $averta
    color: $c-text-alt
    margin-bottom: 16px

  &__stage
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    margin: 16px 0 33px
    padding: 34px 32px
    border: 10px solid $c-white
    border-radius: 3px
    background-color: $c-grey-fine
    +skribble-box-shadow
    +maw(xs)
      padding-top: 32px
      padding-bottom: 32px

  &__upload
    padding: 16px 0

  &__settings
    &:not(:first-of-type)
      border-top: 1px solid $c-border
    :deep(.v-switch .v-selection-control)
      min-height: inherit

  &__switch
    margin: 2 * $bw 0
    &.v-input--is-label-active .v-label
      color: $c-success

    .v-label
      font: bold 1rem/1 $averta
      color: $c-grey

    &__label

      &.active
        font-weight: bold
        color: $c-text-alt
      &.disabled
        color: $c-grey

  &__signature-wrapper
    position: relative
    left: 0
    top: 0
    display: flex
    align-items: stretch
    width: 300px
    height: 140px
    border-radius: 5px
    background-color: $c-white
    font-family: $averta, sans-serif
    font-size: 1rem

.save-element
  position: sticky
  bottom: 0
  > .action
    height: inherit
    display: flex
    align-items: center
    justify-content: center
</style>
