<template>
  <div class="sig-cro">
    <v-dialog v-model="imageModal" persistent max-width="640">
      <v-card class="sig-cro-dialog__card" :drop-padding="true">
        <v-card-title class="text-h5">
          {{ $t('profile.visual_signature.upload.dialog.title') }}
        </v-card-title>
        <v-card-text class="pa-0">
          <cropper
            v-if="uploadedImage"
            class="upload-cropper"
            :src="uploadedImage"
            :stencil-props="{
              handlers: {
                eastNorth: true,
                north: false,
                westNorth: true,
                west: false,
                westSouth: true,
                south: false,
                eastSouth: true,
                east: false,
              },
            }"
            @change="changeStencil"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn size="x-large" :block="$vuetify.display.xs" color="info" variant="outlined" @click="clearLocalImage">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="info"
            size="x-large"
            variant="elevated"
            :class="$vuetify.display.xs ? 'order-first' : ''"
            :block="$vuetify.display.xs"
            @click="uploadImage"
          >
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <skr-button-group size="md" filled>
      <skr-button class="sig-cro__upload-btn" type="secondary" @click="fileInput?.click()">
        <input ref="fileInput" class="d-none" type="file" accept="image/*" @change="loadLocalImage" />
        <template v-if="!isCustomImage">{{ $t('profile.visual_signature.upload.upload_label') }}</template>
        <template v-else>{{ $t('profile.visual_signature.upload.reset_label') }}</template>
      </skr-button>
      <skr-button v-if="isCustomImage" color="error" type="secondary" @click="deleteImage">
        {{ $t('profile.visual_signature.upload.delete_label') }}
      </skr-button>
    </skr-button-group>
  </div>
</template>

<script lang="ts">
import { Cropper } from 'vue-advanced-cropper'

import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import type { CustomImageData } from '@/repository/visualSignature'

import 'vue-advanced-cropper/dist/style.css'

export default defineComponent({
  name: 'SignatureCropper',
  components: {
    Cropper,
    SkrButton,
    SkrButtonGroup,
  },
  props: {
    signatureId: String,
  },
  emits: ['save', 'remove'],
  setup(props) {
    const visualSignatureStore = useVisualSignatureStore()
    const { signatures } = storeToRefs(visualSignatureStore)

    /**
     * Reference to potentially available local image data that has not been saved yet.
     */
    const imageData = inject('imageData', ref<CustomImageData | null>(null))

    const isCustomImage = computed(() => {
      if (!props.signatureId) return Boolean(imageData.value)

      const signature = signatures.value.find(signature => signature.id === props.signatureId)

      return Boolean(signature?.hasCustomImage)
    })

    const fileInput = ref<HTMLInputElement | null>(null)

    return {
      isCustomImage,
      fileInput,
    }
  },
  data() {
    return {
      aspectWidth: 388, // 2 * dimensions.image.width defined in VisualSignature.vue
      aspectHeight: 72, // 2 * dimensions.image.height defined in VisualSignature.vue
      thresholdBase: 110, // start threshold for image thresholding
      thresholdColor: [
        // decimal RGB of Skribble dark grey
        41, 61, 102,
      ],
      thresholdIterationStep: 20, // step size of threshold adjustments
      thresholdMaxIterations: 7, // max number of iterations of threshold adjustments. more than 7 makes no sense at a step of 20 because the max value of a pixel is 255
      thresholdPixelRatio: 0.0025, // minimal ratio of dark pixels after filtering
      imageModal: false,
      uploadedImage: '',
      croppedImage: {
        content: '',
        width: 0,
        height: 0,
      },
    }
  },
  methods: {
    changeStencil(stencil: { canvas: HTMLCanvasElement }) {
      // draw image 3 times bigger than it will be rendered on the visual
      // signature canvas to have a nicer rendering
      const overSamplingFactor = 3
      if (stencil.canvas) {
        const resizeCanvas = document.createElement('canvas')
        const stencilAspectRatio = stencil.canvas.width / stencil.canvas.height
        const maxWidth = overSamplingFactor * this.aspectWidth
        const maxHeight = overSamplingFactor * this.aspectHeight

        if (maxHeight * stencilAspectRatio > maxWidth) {
          // width is limiting
          resizeCanvas.width = maxWidth
          resizeCanvas.height = maxWidth / stencilAspectRatio
        } else {
          // height is limiting
          resizeCanvas.height = maxHeight
          resizeCanvas.width = maxHeight * stencilAspectRatio
        }

        // grab the context from your destination canvas
        const destCtx = resizeCanvas.getContext('2d')
        if (destCtx) {
          // call its drawImage() function passing it the source canvas directly
          destCtx.drawImage(stencil.canvas, 0, 0, resizeCanvas.width, resizeCanvas.height)

          // do some thresholding on the pixel data to remove none-white backgrounds
          // and change color to Skribble's grey-darker (41 61 102)
          // based on https://www.html5rocks.com/en/tutorials/canvas/imagefilters/
          // and https://stackoverflow.com/questions/37512460/thresholding-base64-string-using-javascript

          // we improve on this by doing adaptive thresholding based on the
          // ratio of non-transparent pixels after the filtering to address
          // images with low contrast signature strokes which got filtered out
          // entirely
          let pixels = destCtx.getImageData(0, 0, resizeCanvas.width, resizeCanvas.height)
          let darkPixelRatio = 0
          let adaptiveThreshold = this.thresholdBase
          let iterationCount = 0
          while (darkPixelRatio <= this.thresholdPixelRatio && iterationCount <= this.thresholdMaxIterations) {
            pixels = destCtx.getImageData(0, 0, resizeCanvas.width, resizeCanvas.height)
            let darkPixelCount = 0
            for (let i = 0; i < pixels.data.length; i += 4) {
              const r = pixels.data[i]
              const g = pixels.data[i + 1]
              const b = pixels.data[i + 2]
              const stroke = 0.2126 * r + 0.7152 * g + 0.0722 * b <= adaptiveThreshold
              if (stroke) {
                // set to dark grey
                pixels.data[i] = this.thresholdColor[0]
                pixels.data[i + 1] = this.thresholdColor[1]
                pixels.data[i + 2] = this.thresholdColor[2]
                darkPixelCount += 1
              } else {
                // set to transparent
                pixels.data[i] = 255
                pixels.data[i + 1] = 255
                pixels.data[i + 2] = 255
                pixels.data[i + 3] = 0
              }
            }
            // every pixel has 4 values therefore divide the length by 4 to
            // get the actual number of pixels in the image
            darkPixelRatio = darkPixelCount / pixels.data.length / 4
            adaptiveThreshold += this.thresholdIterationStep
            iterationCount += 1
          }
          destCtx.putImageData(pixels, 0, 0)
        }

        this.croppedImage = {
          content: resizeCanvas.toDataURL(),
          width: resizeCanvas.width,
          height: resizeCanvas.height,
        }
      }
    },
    loadLocalImage(event: Event) {
      const input = event.target as HTMLInputElement
      if (input.files && input.files[0]) {
        this.imageModal = true
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
          // read image as base64 and set to uploadedImage
          const res = e.target?.result
          if (typeof res === 'string') this.uploadedImage = res
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    uploadImage() {
      if (!this.croppedImage.content) return

      this.$emit('save', {
        contentType: 'image/png',
        content: this.croppedImage.content.slice(22),
      })
      this.clearLocalImage()
    },
    deleteImage() {
      this.$emit('remove')
    },
    clearLocalImage() {
      this.imageModal = false
      this.uploadedImage = ''
      this.croppedImage = {
        content: '',
        width: 0,
        height: 0,
      }
      if (this.fileInput) this.fileInput.value = ''
    },
  },
})
</script>

<style lang="sass">

.sig-cro

  &-dialog__card
    .vue-simple-line
      background-color: $c-border

    .cropper
      height: 600px
      background: #DDD

    .preview-image
      object-fit: contain

    .upload-cropper
      height: 300px
      width: 100%

    // cropper stencil stylings
    .vue-square-handler
      background-color: $c-primary

    .vue-advanced-cropper__background
      background: white
</style>
